import axios from 'axios'

const URL = 'https://twofa-api.yukthi.net/'
const HEADERS = {
    'accept': 'application/json',
    'API-Key': 'AXP86wibUaePwf7PrNEKONIK'
}

const fetchTokenDetails =  async(token) => {
    try {        
        const params = {token: token}        
        const response = await axios.get(`${URL}v1/token`,{params: params, headers: HEADERS})
        return response.data
    } catch (error) {
        throw error
    }
}

const approveToken = async(token_data) =>{
    try{
        
        const data = {
            "email": token_data.email,
            "token_id": token_data.token_id,
            "ip": token_data.ip,
            "active": token_data.active,
            "created_at": token_data.created_at,
            "mailbox_id": token_data.mailbox_id,
        }
        const response = await axios.post(`${URL}v1/approve`, data, {headers:HEADERS,})
        if (!response.status === 200){
            throw new Error('Response status not in 200' + response.data)
        }
        return response.data
    }catch (error) {
        throw error
    }
}

const denyToken = async(token_data) =>{
    try{
        
        const data = {
            "email": token_data.email,
            "token_id": token_data.token_id,
            "ip": token_data.ip,
            "active": token_data.active,
            "created_at": token_data.created_at,
            "mailbox_id": token_data.mailbox_id,
        }
        const response = await axios.post(`${URL}v1/deny`, data, {headers:HEADERS,})
        if (!response.status === 200){
            throw new Error('Response status not in 200' + response.data)
        }
        return response.data
    }catch (error) {
        throw error
    }
}


export { fetchTokenDetails, approveToken, denyToken}